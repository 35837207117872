var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":((_vm.editMode ? 'Ubah' : 'Tambah') + " " + (_vm.$route.meta.name.singular)),"no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Skill","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Skill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.skillItems,"state":errors.length > 0 ? false:null},model:{value:(_vm.skillId),callback:function ($$v) {_vm.skillId=$$v},expression:"skillId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Level","label-cols-md":"4","rules":"required"}},[_c('validation-provider',{attrs:{"name":"Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.levelOptions},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Passing Grade","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Passing Grade","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.passingGrade),callback:function ($$v) {_vm.passingGrade=$$v},expression:"passingGrade"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Attachment","label-cols-md":"4"}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Attachment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.attachmentInitialFile ?
                          _vm.attachmentInitialFile.split('/').pop()
                          : "Pilih atau drop file disini...","drop-placeholder":"Drop file disini..."},model:{value:(_vm.attachmentFile),callback:function ($$v) {_vm.attachmentFile=$$v},expression:"attachmentFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.attachmentFile || _vm.attachmentInitialFile)?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lihat File'),expression:"'Lihat File'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"gradient-secondary","target":"_blank","href":_vm.attachmentFile ? _vm.fileUrl(_vm.attachmentFile) : _vm.attachmentInitialFile}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}})],1)],1):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.editMode ? 'warning' : 'primary',"type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }